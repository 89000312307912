import React from 'react';
import BoxWrapper from '../../components/Wrapper/BoxWrapper';
import CardWrapper from '../../components/Wrapper/CardWrapper';
import Button from '../../components/_baseComponents/Button/Button';
import {TbSearch} from '@react-icons/all-files/tb/TbSearch';
import SectionHeading from '../../components/_baseComponents/SectionHeading/SectionHeading';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import {WebDesignQandA} from '../../staticData/WebDesignQandA';
import {DomainsDotVN} from '../../staticData/DomainsDotVN';
import Seo from '../../components/Seo/Seo';
import {graphql, navigate} from 'gatsby';
import useCheckBreakpoint from '../../hooks/useCheckBreakpoint';
import Accordion from '../../components/Accordion/Accordion';

const DomainServicePage = () => {
  const {isDesktop, isTablet, isMobile} = useCheckBreakpoint();
  const domains = DomainsDotVN;

  return (
    <div className="flex flex-col gap-24 pt-[70px] lg:pt-20">
      <BoxWrapper
        className={`flex flex-col items-center justify-center gap-5 lg:w-full`}
      >
        <div className="flex flex-col items-center gap-7">
          <div className="flex flex-col items-center gap-5">
            <div className="flex flex-col items-center gap-3 text-center text-gray-900 ">
              <div className="text-2xl font-bolder md:text-3xl lg:text-4xl ">
                Kiểm tra và đăng ký tên miền
              </div>
              <div className="font-normal md:text-lg lg:text-xl">
                Hãy đăng ký tên miền giá rẻ tại KDIGI ngay hôm nay để bảo vệ
                thương hiệu của bạn
              </div>
            </div>
            {/* Bg effect for mobile*/}
            {isMobile && (
              <div className="relative before:absolute before:-left-[15rem] before:-top-[5rem] before:-z-10 before:block  before:h-[300px] before:w-[300px] before:rounded-full before:bg-blue-500/10 before:blur-[5rem]" />
            )}

            <CardWrapper
              className="w-full rounded-sm border-white bg-white/40 p-1 md:rounded-lg md:p-2 lg:w-[560px]"
              isShadow={false}
            >
              <form className="flex w-full flex-row items-center justify-between gap-2 md:gap-0">
                <input
                  placeholder="Nhập tên miền bạn muốn đăng ký"
                  className="w-full bg-transparent text-base  placeholder:text-gray-900 focus:outline-none lg:text-xl"
                />
                <Button
                  Icon={ButtonIcon}
                  buttonType={`submit`}
                  content={isMobile ? `` : `Tìm kiếm`}
                  className="flex w-fit flex-row-reverse justify-center gap-2 rounded p-1 font-medium md:flex-none md:px-3 md:py-2 lg:flex-none lg:px-6"
                />
              </form>
            </CardWrapper>
          </div>
          <div className="flex w-fit flex-row flex-wrap justify-center gap-2 rounded-lg bg-white p-2  text-xs sm:text-sm md:flex-nowrap md:text-sm lg:text-base">
            <div className="cursor-pointer gap-3 rounded-lg bg-primary/5 py-2 px-3 font-bolder text-primary hover:text-primary">
              Tất cả tên miền
            </div>
            <div className="cursor-pointer gap-3 rounded-lg bg-white py-2 px-3 font-normal text-gray-500 hover:text-primary">
              Tên miền quốc tế
            </div>
            <div className="cursor-pointer gap-3 rounded-lg bg-white py-2 px-3 font-normal text-gray-500 hover:text-primary">
              Tên miền Việt Nam
            </div>
            <div className="cursor-pointer gap-3 rounded-lg bg-white py-2 px-3 font-normal text-gray-500 hover:text-primary">
              Tên miền đặc biệt
            </div>
          </div>
        </div>
        {/* Bg effect */}
        <div className="relative before:absolute before:right-[5rem] before:-top-[20rem] before:-z-10 before:block  before:h-[1140px] before:w-[1140px] before:rounded-full before:bg-blue-500/10 before:blur-[8rem] before:will-change-[filter] before:md:right-[7rem] before:md:-top-[30rem] before:lg:-left-[70rem] before:lg:-top-[40rem]" />
        {/* Bg effect */}
        <div className="relative before:absolute before:left-[5rem] before:-top-[0rem] before:-z-10 before:block  before:h-[740px] before:w-[740px] before:rounded-full before:bg-pink-500/10 before:blur-[8rem] before:will-change-[filter] before:md:left-[7rem] before:md:-top-[30rem] before:lg:-right-[25rem] before:lg:-top-[13rem]" />
        <div className="flex max-w-[1120px] flex-row flex-wrap justify-center gap-3 md:justify-between md:gap-6 lg:justify-center lg:gap-8">
          {domains.map((domain) => (
            <CardWrapper
              className={` ${TailwindTransitions.default[200]} w-32 cursor-pointer items-center justify-center gap-2 rounded-lg border-white bg-white/40 py-5 hover:bg-white sm:w-40 md:w-[30%] lg:w-40`}
              isShadow={false}
              key={domain.domainName}
            >
              <div className="text-center text-lg font-bolder text-gray-900 md:text-xl lg:text-2xl">
                {domain.domainName}
              </div>
              <div className="text-center text-sm font-normal  text-gray-900 md:text-base lg:text-lg">
                {domain.firstYearPrice}
              </div>
            </CardWrapper>
          ))}
        </div>
      </BoxWrapper>
      <BoxWrapper className={`flex flex-col gap-5 lg:w-full`}>
        <div className="flex flex-col items-center justify-between md:flex-row">
          <SectionHeading
            content="Bảng giá tên miền"
            className="items-center md:items-start"
            contentClassName="flex flex-row text-2xl md:text-3xl lg:text-4xl text-gray-900"
          />
          {/* Bg effect for mobile*/}
          {!isDesktop && (
            <div className="relative before:absolute before:-left-[15rem] before:-top-[5rem] before:-z-10 before:block  before:h-[300px] before:w-[300px] before:rounded-full before:bg-red-500/10 before:blur-[6rem]" />
          )}
          <CardWrapper
            className="w-full rounded-sm border-white bg-white/40 p-1 md:rounded-lg md:p-2 lg:w-[560px]"
            isShadow={false}
          >
            <form className="flex w-full flex-row items-center justify-between gap-2 md:gap-0">
              <input
                placeholder="Nhập tên miền bạn muốn đăng ký"
                className="w-full bg-transparent text-base  placeholder:text-gray-900 focus:outline-none lg:text-xl"
              />
              <Button
                Icon={ButtonIcon}
                buttonType={`submit`}
                content={!isDesktop ? `` : `Tìm kiếm`}
                className="flex w-fit flex-row-reverse justify-center gap-2 rounded p-1 font-medium md:flex-none md:px-3 md:py-2 lg:flex-none lg:px-6"
              />
            </form>
          </CardWrapper>
        </div>
        {/* Bg effect */}
        <div className="relative before:absolute before:left-[5rem] before:-top-[0rem] before:-z-10 before:block  before:h-[870px] before:w-[870px] before:rounded-full before:bg-red-500/10 before:blur-[8rem] before:will-change-[filter] before:md:-left-[20rem] before:md:-top-[0rem] before:lg:-left-[25rem] before:lg:-top-[2rem]" />

        <div className="w-full overflow-y-scroll">
          <div className="flex w-[42rem] flex-col gap-3 md:w-full md:gap-4">
            <div className="flex flex-row items-center rounded-md bg-blue-400/10 py-3 px-6 text-lg backdrop:blur-sm lg:rounded-xl lg:py-5 lg:px-10">
              <div className="w-1/4 text-left font-bolder text-gray-700">
                Tên miền
              </div>
              <div className="w-1/4 text-center font-bolder text-gray-700">
                Phí đăng ký năm đầu
              </div>
              <div className="w-1/4 text-center  font-bolder text-gray-700">
                Phí duy trì mỗi năm
              </div>
              <div className="w-1/4 text-center font-bolder text-gray-700">
                Chuyển về
              </div>
            </div>
            <CardWrapper
              className="flex flex-col gap-6 rounded-lg border border-white  bg-white/40 py-3 lg:gap-8 lg:py-5"
              isShadow={false}
            >
              {domains.map((domain) => (
                <div className="group flex w-full cursor-pointer flex-row items-center px-6 text-lg text-gray-700 lg:px-10">
                  <div
                    className={`w-1/4 text-left text-lg font-bolder group-hover:text-primary md:text-2xl ${TailwindTransitions.default[200]}`}
                  >
                    {domain.domainName}
                  </div>
                  <div className="w-1/4 text-center">
                    {domain.firstYearPrice}
                  </div>
                  <div className="w-1/4 text-center">
                    {domain.maintainPrice}
                  </div>
                  <div className="w-1/4 text-center">{domain.chargePrice}</div>
                </div>
              ))}
            </CardWrapper>
          </div>
        </div>
      </BoxWrapper>
      {/* Q&A */}
      <BoxWrapper className={`mt-[3rem] flex-col gap-5 sm:flex-row lg:w-full`}>
        <SectionHeading
          content="Một số câu hỏi thường gặp"
          className={`w-full text-2xl sm:w-[30%] sm:text-2xl xl:text-3xl`}
        />
        <div className={`flex w-full flex-col gap-5 sm:w-[70%]`}>
          <Accordion items={WebDesignQandA} />
        </div>
      </BoxWrapper>
    </div>
  );
};
const ButtonIcon = () => <TbSearch className="h-4 w-4 md:h-6 md:w-6" />;

export default DomainServicePage;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/dich-vu/dich-vu-ten-mien"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
