import React from 'react';
import {TailwindTransitions} from '../../../utils/library/transitions/transitions';
/**
 *
 * @param {Object} props
 * @param {string} props.className - Class name for button. Default: `py-2 px-6`.
 * @param {string} props.type - Button type: `primary` and `outline`. Default: `primary`
 * @param {string} props.buttonType - Attribute type of Button: `submit`, ...
 * @param {boolean} props.isDisabled - Set button is disabled. Default: `false`
 * @param {string} props.content - Content/Label of the button
 * @param {JSX.Element} props.Icon - Icon for the button. Default is null
 * @param {()=>{}} props.onClick - Handler for onClick event
 * @returns
 */
const Button = ({
  content,
  Icon = () => <></>,
  type = 'primary',
  buttonType = 'button',
  isDisabled = false,
  className = 'py-2 px-6',
  onClick,
}) => {
  let buttonStyle;

  switch (type) {
    case 'primary':
      buttonStyle = 'bg-primary text-white hover:bg-primary/80';
      break;
    case 'outline':
      buttonStyle = 'text-primary hover:bg-primary hover:text-white';
      break;

    default:
      break;
  }
  return (
    <button
      disabled={isDisabled}
      type={buttonType}
      onClick={onClick}
      className={`${buttonStyle} ${TailwindTransitions.default[200]} flex cursor-pointer flex-row items-center rounded-md border border-primary  ${className}`}
    >
      {content}
      <Icon />
    </button>
  );
};

export default Button;
