import React, {forwardRef} from 'react';

/**
 * A Component that render a card layout. You should provide a background color and padding for this component to render correctly
 * @param {Object} props
 * @param {boolean} props.isBlur Turn on to blur the background. Note: background must have alpha value < 1 for this setting to be effective
 * @param {boolean} props.isShadow Turn on to add shadow to card. Default: `true`
 * @param {string} props.breakpoint Breakpoint to apply card wrapper style. Follow Tailwind breakpoint system: `md`, `lg` and `null` (default)
 * @param {string} props.roundedClass Card wrapper rounded level. Use Tailwind `rounded-*`. Default is `rounded-xl`
 * @returns
 */
const CardWrapper = forwardRef(
  (
    {
      children,
      className,
      isBlur,
      isShadow = true,
      breakpoint,
      roundedClass = 'rounded-xl',
      borderClassName = 'border border-solid border-white/30',
    },
    ref
  ) => {
    let renderClass = '';
    switch (breakpoint) {
      case 'md':
        renderClass = `md:border md:border-solid md:border-white/30 ${
          isShadow && 'md:shadow-card-layout md:shadow-neutral-500/10'
        }`;
        break;

      case 'lg':
        renderClass = `lg:border lg:border-solid lg:border-white/30 ${
          isShadow && 'lg:shadow-card-layout lg:shadow-neutral-500/10'
        }`;
        break;
      default:
        renderClass = `${borderClassName} ${
          isShadow && 'shadow-card-layout shadow-neutral-500/10'
        }`;
        break;
    }

    return (
      <div
        ref={ref}
        className={`${renderClass} ${roundedClass} ${
          isBlur ? 'backdrop-blur-xl' : ''
        } ${className ?? ''}`}
      >
        {children}
      </div>
    );
  }
);

export default CardWrapper;
