export const DomainsDotVN = [
  {
    domainName: '.vn',
    firstYearPrice: '770.000đ',
    maintainPrice: '460.000đ',
    chargePrice: '',
  },
  {
    domainName: '.com.vn',
    firstYearPrice: '670.000đ',
    maintainPrice: '360.000đ',
    chargePrice: '',
  },
  {
    domainName: '.net.vn',
    firstYearPrice: '670.000đ',
    maintainPrice: '360.000đ',
    chargePrice: '',
  },
  {
    domainName: '.biz.vn',
    firstYearPrice: '670.000đ',
    maintainPrice: '360.000đ',
    chargePrice: '',
  },
  {
    domainName: '.gov.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.org.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.edu.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.pro.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.ac.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.gov.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.info.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.health.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.int.vn',
    firstYearPrice: '490.000đ',
    maintainPrice: '260.000đ',
    chargePrice: '',
  },
  {
    domainName: '.name.vn',
    firstYearPrice: '82.000đ',
    maintainPrice: '41.000đ',
    chargePrice: '',
  },
  {
    domainName: '.vn có 1 ký tự',
    firstYearPrice: '40.420.000đ',
    maintainPrice: '40.110.000đ',
    chargePrice: '',
  },
  {
    domainName: '.vn có 2 ký tự',
    firstYearPrice: '10.420.000đ',
    maintainPrice: '10.110.000đ',
    chargePrice: '',
  },
  {
    domainName: 'tiếng Việt',
    firstYearPrice: '42.000đ',
    maintainPrice: '42.000đ',
    chargePrice: '',
  },
];
