// import {useEffect, useState} from 'react';
import useWindowDimensions from './useWindowDimensions';

/**
 * Check if current screen is desktop, tablet or mobile.
 *
 * WARNING: When using this hook to render content conditionally, you must satisfy 2 conditions:
 * 1. Use `useState` hook to store return data.
 * 2. Use this hook inside `useEffect` hook.
 *
 * If the above conditions are not satisfied, React may return `Hydration` error!
 * @returns
 */
export default function useCheckBreakpoint() {
  const dimension = useWindowDimensions();
  let isDesktop = dimension?.width >= 1024;
  let isTablet = dimension?.width < 1024 && dimension?.width >= 768;
  let isMobile = !isDesktop && !isTablet;

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
}
